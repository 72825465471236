import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { SocialIcon } from '@/components/SocialIcon'
import { Link } from '@/components/Link'
import * as styles from './Footer.styles.scss'
import IllustrationSvg from './images/footer.inline.svg'
import EdgeSvg from './images/edge.inline.svg'
import variants from './Footer.motion'

const Footer = ({ heading, socialLinks, notes, contact }) => {
  const { ref, inView } = useInView()

  return (
    <motion.footer
      className={styles.el}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      <div className={styles.edge} ref={ref}>
        <EdgeSvg />
      </div>
      <Container>
        <div className={styles.inner}>
          <motion.div
            className={styles.illustration}
            variants={variants.illustration}
          >
            <IllustrationSvg />
          </motion.div>
          <h4 className={styles.heading}>
            <RichText>{heading}</RichText>
          </h4>
          {!!socialLinks.length && (
            <div className={styles.social}>
              <div className={styles.contact}>
                <Link to="/contact">Contact</Link>
              </div>
              {/* {!!contact && (
                <div className={styles.contact}>
                  <a href={`mailto:${contact}`}>Contact</a>
                </div>
              )} */}
              {socialLinks.map((item) => {
                return (
                  <div key={item.id} className={styles.socialLink}>
                    <Link to={item.url} target="_blank">
                      <SocialIcon type={item.type} />
                    </Link>
                  </div>
                )
              })}
            </div>
          )}
          {!!notes && (
            <div className={styles.notes}>
              <RichText>{notes}</RichText>
            </div>
          )}
        </div>
      </Container>
    </motion.footer>
  )
}

Footer.defaultProps = {
  socialLinks: [],
}

Footer.propTypes = {
  heading: PropTypes.node,
  socialLinks: PropTypes.array,
  notes: PropTypes.node,
  contact: PropTypes.string,
}

export { Footer }
