import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import * as styles from './Illustration.styles.scss'
import variants from './Illustration.motion'
import { icons } from './icons'

const Illustration = ({ id }) => {
  if (icons[id]) {
    return (
      <motion.div className={styles.el} initial="hidden" animate="visible">
        <motion.div className={styles.base} variants={variants.base}>
          <div className={styles.base__bg}>{icons[id].bg}</div>
          <div className={styles.base__frame}>{icons[id].frame}</div>
        </motion.div>
        <motion.div className={styles.icon} variants={variants.icon}>
          {icons[id].icon}
        </motion.div>
      </motion.div>
    )
  }
  console.warn(`${id} not found`) // eslint-disable-line no-console
  return null
}

Illustration.defaultProps = {
  id: undefined,
}

Illustration.propTypes = {
  id: PropTypes.string,
}

export { Illustration }
