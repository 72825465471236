const motion = {
  illustration: {
    hidden: {
      scaleY: 0,
    },
    visible: {
      scaleY: 1,
    },
  },
}

export default motion
