import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './SocialIcon.styles.scss'

import FacebookSvg from './images/facebook.inline.svg'
import InstagramSvg from './images/instagram.inline.svg'
import LinkedinSvg from './images/linkedin.inline.svg'
import TwitterSvg from './images/twitter.inline.svg'
import VimeoSvg from './images/vimeo.inline.svg'
import YoutubeSvg from './images/youtube.inline.svg'

const ICONS = [
  { id: 'facebook', icon: FacebookSvg },
  { id: 'instagram', icon: InstagramSvg },
  { id: 'linkedin', icon: LinkedinSvg },
  { id: 'twitter', icon: TwitterSvg },
  { id: 'vimeo', icon: VimeoSvg },
  { id: 'youtube', icon: YoutubeSvg },
]

const SocialIcon = ({ type }) => {
  const match = ICONS.find((network) => network.id === type)
  const Icon = match && match.icon ? match.icon : undefined

  if (Icon) {
    return (
      <div className={styles.el}>
        <Icon />
      </div>
    )
  }
  return null
}

SocialIcon.defaultProps = {
  type: 'facebook',
}

SocialIcon.propTypes = {
  type: PropTypes.string,
}

export { SocialIcon }
