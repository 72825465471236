const colors = {
  black: '#1a1a1a',
  white: '#f4f2ef',
  default: '#f0ad3c',
  yellow: '#f0ad3c',
  orange: '#dd573c',
  green: '#2e7943',
  pink: '#e586ac',
  purple: '#685c97',
}

export { colors }
