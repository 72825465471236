import React, { useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Container } from '@/components/Container'
import { Theme } from '../Theme'
import { Illustration } from '../Illustration'
import animations from './Menu.animations'
import * as styles from './Menu.styles.scss'

const Menu = ({ isModal, isModalOpen, nav, onClickClose, contact }) => {
  const [isInterative, setIsInteractive] = useState(!isModalOpen)

  const handleClickNav = (event, url) => {
    event.preventDefault()
    if (isModal) {
      onClickClose()
      setTimeout(() => navigate(url), 300)
    } else {
      navigate(url)
    }
  }

  return (
    <motion.nav
      className={styles.el}
      data-modal={isModal}
      initial="hidden"
      animate={isModalOpen ? 'visible' : 'hidden'}
      data-interactive={isInterative}
      onAnimationStart={() => setIsInteractive(true)}
      onAnimationComplete={() => setIsInteractive(!isModalOpen)}
    >
      {isModal && <motion.div className={styles.bg} variants={animations.bg} />}
      <Container className={styles.container}>
        <div className={styles.inner}>
          {isModal && (
            <div className={styles.header}>
              <motion.div className={styles.navItem} variants={animations.nav}>
                <button type="button" onClick={onClickClose}>
                  Close
                </button>
              </motion.div>
              {!!contact && (
                <motion.div
                  className={styles.navItem}
                  variants={animations.nav}
                >
                  <a href={`mailto:${contact}`}>Contact</a>
                </motion.div>
              )}
            </div>
          )}
          <div className={styles.items}>
            <ul className={styles.list}>
              {nav.map((item) => {
                return (
                  <li key={item.id} className={styles.nav__item}>
                    <Theme theme={item.color}>
                      <motion.div
                        className={styles.nav__item__inner}
                        variants={animations.sticker}
                      >
                        <a
                          href={item.url}
                          onClick={(event) => handleClickNav(event, item.url)}
                          className={styles.nav__link}
                          aria-label={item.title}
                        >
                          <Illustration id={item.icon} />
                        </a>
                      </motion.div>
                    </Theme>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Container>
    </motion.nav>
  )
}

Menu.defaultProps = {
  isModal: false,
  isModalOpen: true,
  nav: [],
  onClickClose: () => {},
  contact: undefined,
}

Menu.propTypes = {
  isModal: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  nav: PropTypes.array,
  onClickClose: PropTypes.func,
  contact: PropTypes.string,
}

export { Menu }
