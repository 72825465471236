const animations = {
  bg: {
    hidden: {
      scaleY: 0,
      transition: {
        duration: 0.5,
        delay: 0.1,
      },
    },
    visible: {
      scaleY: 1,
      transition: {
        duration: 0.5,
      },
    },
  },
  nav: {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 1,
      transition: {
        delay: 0.5,
      },
    },
  },
  sticker: {
    hidden: {
      scale: 0,
      transition: {
        delay: 0,
      },
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.25,
      },
    },
  },
}

export default animations
