const motion = {
  base: {
    hidden: {
      scale: 0.5,
      rotate: 20,
    },
    visible: {
      scale: 1,
      rotate: 0,
    },
  },
  icon: {
    hidden: {
      scale: 0.2,
      rotate: 60,
    },
    visible: {
      scale: 1,
      rotate: 0,
    },
  },
}

export default motion
