import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

function Metadata({
  title,
  defaultTitle,
  titleTemplate,
  description,
  image,
  favicon,
  lang,
  siteUrl,
  path,
  type,
}) {
  const shareTitle = title
    ? (titleTemplate && titleTemplate.replace('%s', title)) || title
    : defaultTitle
  const pageURL = `${siteUrl}${path}`

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate} prioritizeSeoTags>
      <meta charSet="utf-8" />
      <html lang={lang} />
      <title>{title}</title>
      <link rel="canonical" href={pageURL} />
      <link rel="icon" href={favicon} />
      <meta name="description" content={description} />
      <meta name="robots" content="index, follow" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,maximum-scale=5.0"
      />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={shareTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={pageURL} />
      <meta property="og:site_name" content={siteUrl} />
      <meta name="twitter:title" content={shareTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary" />
    </Helmet>
  )
}

Metadata.defaultProps = {
  titleTemplate: undefined,
  favicon: undefined,
  lang: 'en',
  path: '/',
  type: 'website',
}

Metadata.propTypes = {
  title: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  titleTemplate: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  favicon: PropTypes.string,
  lang: PropTypes.string,
  siteUrl: PropTypes.string.isRequired,
  path: PropTypes.string,
  type: PropTypes.string,
}

export { Metadata }
