import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getLink } from '../utils/getLink'
import { getRichText } from '../utils/getRichText'
import { getSlug } from '@/utils/getSlug'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const configData = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        prismicSiteConfig {
          data {
            title
            meta_description
            meta_subtitle
            meta_image {
              url
            }
            contact_email
            blog_slug
            blog_title
            blog_enabled
            nav {
              link {
                document {
                  ... on PrismicFlexible {
                    uid
                    type
                  }
                  ... on PrismicHome {
                    uid
                    type
                  }
                  ... on PrismicBlogPost {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              title
              icon
              color
            }
            social_links {
              title
              type
              url
            }
            footer_heading {
              raw
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = configData.gatsbyConfig.siteMetadata || {}
  const prismicConfig = configData.prismicSiteConfig.data || {}

  const nav = []
  if (prismicConfig.nav) {
    prismicConfig.nav.forEach((item, index) => {
      nav.push({
        ...getLink(item.link, item.title, `nav_${index}`),
        icon: getSlug(item.icon),
        color: getSlug(item.color),
      })
    })
  }

  if (prismicConfig.blog_enabled) {
    nav.push({
      id: 'nav_blog',
      title: prismicConfig.blog_title,
      url: `/${prismicConfig.blog_slug}`,
      icon: 'shit',
      color: 'yellow',
    })
  }

  const socialLinks = []
  if (prismicConfig.social_links) {
    prismicConfig.social_links.forEach((item, index) => {
      socialLinks.push({
        id: `social_link_${index}`,
        title: item.title || item.type,
        type: getSlug(item.type),
        url: item.url,
      })
    })
  }

  return {
    metadata: {
      title: prismicConfig.title || gatsbyConfig.title,
      description: prismicConfig.meta_description || gatsbyConfig.description,
      subtitle: prismicConfig.meta_subtitle || gatsbyConfig.subtitle,
      image: get(prismicConfig, 'meta_image.url') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    contact: prismicConfig.contact_email,
    nav,
    blog: {
      enabled: prismicConfig.blog_enabled,
      slug: prismicConfig.blog_slug,
      title: prismicConfig.blog_title,
    },
    footer: {
      heading: getRichText(prismicConfig, 'footer_heading.raw'),
      socialLinks,
      contact: prismicConfig.contact_email,
      notes: (
        <p>
          Website by{' '}
          <a
            href="https://www.standandmarvel.com/"
            target="_blank"
            rel="noreferrer"
          >
            Stand and Marvel
          </a>
          .
        </p>
      ),
    },
  }
}
