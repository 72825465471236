import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Link } from '../Link'
import { BtnMenu } from '../BtnMenu'
import * as styles from './Header.styles.scss'

const Header = ({ isInverse, onClickMenu, title }) => {
  return (
    <header className={styles.el} data-inverse={isInverse}>
      <Container>
        <div className={styles.inner}>
          <h1 className={styles.logo}>
            <Link to="/" aria-label={title}>
              {title}
            </Link>
          </h1>
          <BtnMenu onClick={onClickMenu} />
        </div>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  isInverse: false,
  onClickMenu: () => {},
  title: undefined,
}

Header.propTypes = {
  isInverse: PropTypes.bool,
  onClickMenu: PropTypes.func,
  title: PropTypes.string,
}

export { Header }
