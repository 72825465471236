// extracted by mini-css-extract-plugin
export var aniSpin = "G_d";
export var contact = "G_bn";
export var edge = "G_bh";
export var el = "G_b";
export var heading = "G_F";
export var illustration = "G_bk";
export var inner = "G_r";
export var notes = "G_bj";
export var social = "G_bl";
export var socialLink = "G_bm";