import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Metadata } from '@/components/Metadata'
import { Menu } from '@/components/Menu'
import { Theme } from '@/components/Theme'
import { Header } from '@/components/Header'
import { Footer } from '@/components/Footer'
import { useViewportHeight } from '@/hooks/useViewportHeight'
import favicon from '@/images/favicon.png'
import '@/styles/main.scss'

import * as styles from './Layout.styles.scss'

const Layout = ({ site, page, children }) => {
  useViewportHeight()
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleOpenMenu = () => {
    setMenuIsOpen(true)
  }

  const handleCloseMenu = () => {
    setMenuIsOpen(false)
  }

  return (
    <>
      <Metadata
        title={page.title}
        defaultTitle={
          site.metadata.subtitle
            ? `${site.metadata.title}: ${site.metadata.subtitle}`
            : site.metadata.title
        }
        titleTemplate={`%s — ${site.metadata.title}`}
        favicon={favicon}
        description={site.metadata.description}
        image={page.image || site.metadata.image}
        siteUrl={site.metadata.siteUrl}
        path={page.path}
      />
      <Theme className={styles.el} theme={page.theme}>
        <Header
          nav={site.nav}
          title={site.metadata.title}
          onClickMenu={handleOpenMenu}
          isInverse={page.isHeaderInverse}
        />
        <Menu
          nav={site.nav}
          onClickClose={handleCloseMenu}
          isModal
          isModalOpen={menuIsOpen}
          contact={site.contact}
        />
        <main className={styles.main}>{children}</main>
        <Footer {...site.footer} />
      </Theme>
    </>
  )
}

Layout.defaultProps = {
  site: {
    metadata: {},
    favicon: undefined,
    nav: {},
    contact: undefined,
    footer: {},
  },
  page: {
    title: undefined,
    image: undefined,
    isHeaderInverse: false,
    path: undefined,
    theme: 'default',
  },
}

Layout.propTypes = {
  site: PropTypes.shape({
    metadata: PropTypes.object,
    favicon: PropTypes.string,
    nav: PropTypes.arrayOf(PropTypes.object),
    contact: PropTypes.string,
    footer: PropTypes.object,
  }),
  page: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    isHeaderInverse: PropTypes.bool,
    path: PropTypes.string,
    theme: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
}

export default Layout
