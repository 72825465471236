import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { colors } from '@/constants/colors'

const IS_BROWSER = typeof document !== 'undefined'

const Theme = ({ children, className, theme, ...attributes }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (IS_BROWSER && ref.current) {
      ref.current.style.setProperty('--themeColor', colors[theme])
    }
  }, [theme, ref])

  return (
    <div className={className} {...attributes} ref={ref}>
      {children}
    </div>
  )
}

Theme.defaultProps = {
  className: undefined,
  theme: 'default',
  children: undefined,
}

Theme.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  children: PropTypes.node,
}

export { Theme }
