import React from 'react'

import CoffeePotIcon from './images/coffee-pot-icon.inline.svg'
import CoffeePotFrame from './images/coffee-pot-frame.inline.svg'
import CoffeePotBg from './images/coffee-pot-bg.inline.svg'

import MicrophoneIcon from './images/microphone-icon.inline.svg'
import MicrophoneFrame from './images/microphone-frame.inline.svg'
import MicrophoneBg from './images/microphone-bg.inline.svg'

import TapHeadIcon from './images/tap-head-icon.inline.svg'
import TapHeadFrame from './images/tap-head-frame.inline.svg'
import TapHeadBg from './images/tap-head-bg.inline.svg'

import ThumbsUpIcon from './images/thumbs-up-icon.inline.svg'
import ThumbsUpFrame from './images/thumbs-up-frame.inline.svg'
import ThumbsUpBg from './images/thumbs-up-bg.inline.svg'

import VinylIcon from './images/vinyl-icon.inline.svg'
import VinylFrame from './images/vinyl-frame.inline.svg'
import VinylBg from './images/vinyl-bg.inline.svg'

import ShitIcon from './images/shit-icon.inline.svg'
//import ShitFrame from './images/shit-frame.inline.svg'
import ShitBg from './images/shit-bg.inline.svg'

const icons = {
  'coffee-pot': {
    icon: <CoffeePotIcon />,
    frame: <CoffeePotFrame />,
    bg: <CoffeePotBg />,
  },
  microphone: {
    icon: <MicrophoneIcon />,
    frame: <MicrophoneFrame />,
    bg: <MicrophoneBg />,
  },
  shit: {
    icon: <ShitIcon />,
    //frame: <ShitFrame />,
    bg: <ShitBg />,
  },
  'tap-head': {
    icon: <TapHeadIcon />,
    frame: <TapHeadFrame />,
    bg: <TapHeadBg />,
  },
  'thumbs-up': {
    icon: <ThumbsUpIcon />,
    frame: <ThumbsUpFrame />,
    bg: <ThumbsUpBg />,
  },
  'vinyl-record': {
    icon: <VinylIcon />,
    frame: <VinylFrame />,
    bg: <VinylBg />,
  },
}

export { icons }
