/**
 * Link Resolver for Prismic content
 *
 * @param {object} page
 */

exports.linkResolver = (page) => {
  if (page.type === 'home') {
    return `/`
  }

  // pagination
  if (page.pageNumber >= 2) {
    return `/${page.uid}/${page.pageNumber}`
  }

  if (page.parent) {
    return `/${page.parent}/${page.uid}`
  }

  // fallback route
  return `/${page.uid}`
}
